<template>
  <v-container fill-height align-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column class="pa-4">
      <v-layout row wrap class="ma-0 flex-none" align-center>
        <h2 class="raleway grey--text text--darken-2 mt-3 mb-4" :class="{ 'display-2': mdAndUp, 'display-1': smAndDown }">
          {{ $t('tickets.title') }}
        </h2>
      </v-layout>
      <booking-info class="mt-4" />
      <booking-guests class="mt-4" @update="refreshGuests()" />
      <booking-orders v-if="booking && booking.accountId" class="mt-4" />
    </v-layout>
  </v-container>
</template>

<script>
import vuetifyMixins from '@/mixins/Vuetify'
import routeNames from '@/router/routes'
import { mapState } from 'vuex'

export default {
  created () {
    this.$store.dispatch('bookings/get', { id: this.$route.params.id, save: true, decorate: true })
    this.refreshGuests()
  },
  computed: {
    ...mapState({
      booking: (state) => state.bookings.read
    })
  },
  methods: {
    refreshGuests () {
      this.$store.commit('bookings/setGuests', [])
      this.$store.dispatch('bookings/getGuests', { id: this.$route.params.id, save: true, decorate: true })
    }
  },
  components: {
    BookingInfo: () => import('@/components/view-components/bookings/BookingInfo.vue'),
    BookingGuests: () => import('@/components/view-components/bookings/BookingGuests.vue'),
    BookingOrders: () => import('@/components/view-components/bookings/BookingOrders.vue')
  },
  mixins: [
    vuetifyMixins,
  ],
  beforeDestroy () {
    this.$store.commit('bookings/resetRead')
    this.$store.commit('orders/resetList')
  }
}
</script>
