export default {
  computed: {
    xsOnly () {
      return this.$vuetify.breakpoint.xsOnly
    },
    smOnly () {
      return this.$vuetify.breakpoint.smOnly
    },
    mdOnly () {
      return this.$vuetify.breakpoint.mdOnly
    },
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    smAndUp () {
      return this.$vuetify.breakpoint.smAndUp
    },
    mdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    xlAndUp () {
      return this.$vuetify.breakpoint.xlAndUp
    }
  }
}
